@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('assets/font/Poppins-Regular.ttf') format('truetype');
  /* Ubah path sesuai dengan lokasi font Poppins di proyek Anda */
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html,
body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  background-color: #f3f4f6;
  font-family: 'Poppins', sans-serif;
}

#root {
  @apply h-full;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
  z-index: 1000; /* Must be greater than #side-dragbar:z-index */
}

.render-support {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
  -webkit-transform: translateZ(0) scale(1, 1);
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: rgb(43, 62, 230);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px rgb(43, 62, 230), 0 0 5px rgb(43, 62, 230);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: rgb(43, 62, 230);
  border-left-color: rgb(43, 62, 230);
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.card-costume {
  background-color: #fff;
  color: #2a3547;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  overflow: hidden;
  padding: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.hover-darker:hover {
  background-color: darkgray;
}

.rs-input {
  font-size: 12px !important;
}

.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 120;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.react-international-phone-input-container
  > .react-international-phone-country-selector
  > .react-international-phone-country-selector-button {
  padding: 0px 12px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.react-international-phone-input-container > .react-international-phone-input {
  width: 100%;
  padding: 0px 12px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.react-international-phone-input-container > .react-international-phone-input:focus {
  width: 100%;
  padding: 0px 12px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid #5d87ff;
}

.react-international-phone-input-container
  > .react-international-phone-country-selector
  > .react-international-phone-country-selector-button:disabled {
  background-color: #f9fafb;
  cursor: not-allowed;
  opacity: 1;
}

.react-international-phone-input-container > .react-international-phone-input:disabled {
  background-color: #f9fafb;
  cursor: not-allowed;
  opacity: 1;
  border: 1px solid #e5e7eb;
  color: #6b7280;
}
